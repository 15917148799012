import { Grid2 as Grid, Typography } from "@mui/material";
import { TextLoop } from "react-text-loop-next";
import useMobileHook from "../Hooks";

const Header = ({ from }) => {
  const isMobile = useMobileHook();

  return (
    <Grid style={{ marginTop: "10%" }}>
      <Grid>
        {from === "home" && (
          <>
            <Grid style={{ display: "flex" }}>
              <Typography
                variant={isMobile ? "body1Mobile" : "h1"}
                style={{ whiteSpace: "nowrap" }}
              >
                Enhance your
              </Typography>
              <TextLoop>
                <Typography
                  variant={isMobile ? "body1Mobile" : "h2"}
                  style={{ margin: 0, padding: 0, color: "#00B1D9" }}
                >
                  &nbsp;sales
                </Typography>
                <Typography
                  variant={isMobile ? "body1Mobile" : "h2"}
                  style={{ margin: 0, padding: 0, color: "#00B1D9" }}
                >
                  &nbsp;market
                </Typography>
                <Typography
                  variant={isMobile ? "body1Mobile" : "h2"}
                  style={{ margin: 0, padding: 0, color: "#00B1D9" }}
                >
                  &nbsp;revenue
                </Typography>
                <Typography
                  variant={isMobile ? "body1Mobile" : "h2"}
                  style={{ margin: 0, padding: 0, color: "#00B1D9" }}
                >
                  &nbsp;methods
                </Typography>
                <Typography
                  variant={isMobile ? "body1Mobile" : "h2"}
                  style={{ margin: 0, padding: 0, color: "#00B1D9" }}
                >
                  &nbsp;coverge
                </Typography>
              </TextLoop>
            </Grid>
            <Typography variant={isMobile ? "body1Mobile" : "h1"}>
              in Latin America
            </Typography>
            <Typography variant="h4" style={{ marginTop: "10px" }}>
              We are the new way of crossborder
            </Typography>
            <Typography variant="h4"> payment in Latam.</Typography>
          </>
        )}
        {from === "ourCompany" && (
          <>
            <Grid style={{ display: "flex" }}>
              <Typography variant={isMobile ? "body1Mobile" : "h1"}>
                Our Company
              </Typography>
            </Grid>
            <Typography
              fontSize={isMobile && "16px"}
              variant="h4"
              style={{ marginTop: "10px" }}
            >
              At Rixsus we are focus in becoming the most
            </Typography>
            <Typography fontSize={isMobile && "16px"} variant="h4">
              {" "}
              innovation and solid platform of payments in
            </Typography>
            <Typography fontSize={isMobile && "16px"} variant="h4">
              {" "}
              Latam, with the idea of people first.
            </Typography>
          </>
        )}
        {from === "contactUs" && (
          <>
            <Grid style={{ display: "flex" }}>
              <Typography variant={isMobile ? "body1Mobile" : "h1"}>
                Contact Us
              </Typography>
            </Grid>
            <Typography
              fontSize={isMobile && "16px"}
              variant="h4"
              style={{ marginTop: "10px" }}
            >
              We'd like to hear from you.
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default Header;
