import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import imgBg from "../../img/tac-background.png";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import Navbar from "../Navbar";
import Footer from "../Footer";
import useMobileHook from "../Hooks";

const countries = [
  {
    name: "Argentina",
    policies: [
      {
        label: "Términos y Condiciones",
        url: "https://rixsusassets.s3.us-east-2.amazonaws.com/ar/documents/terms-conditions.pdf",
      },
      {
        label: "Política de Privacidad",
        url: "https://rixsusassets.s3.us-east-2.amazonaws.com/ar/documents/privacy-policy.pdf",
      },
    ],
  },
  {
    name: "Brazil",
    policies: [
      {
        label: "Declaração de Tratamento de Dados Pessoais",
        url: "https://rixsusassets.s3.us-east-2.amazonaws.com/br/documents/personal-data.pdf",
      },
      {
        label: "Política de Privacidade",
        url: "https://rixsusassets.s3.us-east-2.amazonaws.com/br/documents/privacy-policy.pdf",
      },
    ],
  },
  {
    name: "Colombia",
    policies: [
      {
        label: "Política de Privacidad",
        url: "https://rixsusassets.s3.us-east-2.amazonaws.com/co/documents/privacy-policy.pdf",
      },
    ],
  },
  {
    name: "Mexico",
    policies: [
      {
        label: "Política de Privacidad",
        url: "https://rixsusassets.s3.us-east-2.amazonaws.com/mx/documents/privacy-policy.pdf",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    alignItems: "center",
  },

  titleSection: {
    //width: "1190px",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0 70px 0",
  },
  titleSubSection: {
    width: "100%",
    display: "flex",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  mainTitle: {
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "36px",
    lineHeight: "65px",
  },
  secondaryTitle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    color: "#505050",
    textAlign: "left",
  },

  subContainer: {
    //width: "1190px",
    height: "100%",
    background: "#ffffff",
    mixBlendMode: "normal",
    boxShadow: "0px 0px 44px rgba(104, 72, 72, 0.4)",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },

  dot: {
    height: "15px",
    width: "15px",
    backgroundColor: "#000",
    borderRadius: "50%",
    display: "inline-block",
  },
  dotMin: {
    height: "5px",
    width: "5px",
    margin: "0 5px",
    backgroundColor: "#585858",
    borderRadius: "50%",
    display: "inline-block",
  },

  //aca
  main: {
    backgroundImage: `url(${imgBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    webkitBackgroundSize: "contain",
    oBackgroundSize: "contain",
    mozBackgroundSize: "contain",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${imgBg})`,
      backgroundColor: "transparent",
      backgroundSize: "initial",
      webkitBackgroundSize: "initial",
      oBackgroundSize: "initial",
      mozBackgroundSize: "initial",
    },
  },
  accordion: {
    padding: "20px",
    margin: "0 !important",
  },

  header: {
    backgroundColor: "transparent",
    webkitTransition: "background-color 700ms linear",
    msTransition: "background-color 700ms linear",
    transition: "background-color 700ms linear",
  },
  header2: {
    backgroundColor: "#FFF",
    zIndex: "9999999",
    webkitTransition: "background-color 400ms linear",
    msTransition: "background-color 400ms linear",
    transition: "background-color 400ms linear",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  subheader: {
    textAlign: "left",
    fontStyle: "normal !important",
    fontWeight: "normal !important",
    fontSize: "16px !important",
    lineHeight: "19px !important",
    color: "#505050 !important",
    marginTop: "10px !important",
  },
  subheaderWrapper: {
    paddingTop: "5px",
    width: "670px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

const Policy = () => {
  const classes = useStyles();
  const isMobile = useMobileHook();

  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div className={classes.main}>
      <div
        className={classes[header]}
        style={{
          position: "sticky",
          width: "100%",
          top: "0px",
        }}
      >
        <Navbar showHorizontalBar={header === "header"} />
      </div>
      <Container
        maxWidth="xl"
        style={{
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className={classes.titleSection}>
          <div className={classes.titleSubSection}>
            <Typography variant={isMobile ? "subTitleMobile" : "h3"}>
              Policies
            </Typography>
          </div>
        </div>

        <div className={classes.subContainer}>
          {countries.map((country) => (
            <List
              key={country.name}
              subheader={<ListSubheader>{country.name}</ListSubheader>}
            >
              {country.policies.map((policy) => (
                <ListItem key={policy.label} dense>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Link target="_blank" href={policy.url}>
                        {policy.label}
                      </Link>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ))}
        </div>
      </Container>
      <Footer />
    </div>
  );
};
export default Policy;
