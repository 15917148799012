import {
  Grid2 as Grid,
  Typography,
  Link as MaterialLink,
  Container,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import email from "../../img/email.svg";
import useMobileHook from "../Hooks";

import "./styles.css";
import { Email } from "react-obfuscate-email";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    padding: "50px 0",
    width: "100%",
    display: "flex",
    flexDirection: "column !important",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 0 50px 0",
    },
  },
  linksWrapper: {
    margin: "60px 0",
    justifyContent: "center",
    alignSelf: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      margin: "30px",
      whiteSpace: "nowrap",
      zIndex: "99",
    },
  },
  linkWrapper: {
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#00B1D9",
  },
  link: {
    margin: "0 10px !important",
    zIndex: "99999",
  },
  bottomWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column !important",
    },
  },

  dot: {
    height: "5px",
    width: "5px",
    backgroundColor: "#00B1D9",
    borderRadius: "50%",
    display: "inline-block",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const isMobile = useMobileHook();
  return (
    <Container maxWidth="xl">
      <Grid className={classes.root}>
        <Grid className={classes.linksWrapper}>
          <Grid className={classes.linkWrapper}>
            <MaterialLink
              to="/"
              component={Link}
              underline="none"
              className={classes.link}
            >
              Home
            </MaterialLink>
            <span className={classes.dot}></span>
          </Grid>
          <Grid className={classes.linkWrapper}>
            <MaterialLink
              to="/our-company"
              component={Link}
              underline="none"
              className={classes.link}
            >
              Our Company
            </MaterialLink>
            <span className={classes.dot}></span>
          </Grid>
          <Grid className={classes.linkWrapper}>
            <MaterialLink
              to="/contact-us"
              component={Link}
              underline="none"
              className={classes.link}
            >
              Contact Us
            </MaterialLink>
            <span className={classes.dot}></span>
          </Grid>
          <Grid className={classes.linkWrapper}>
            <MaterialLink
              to="/policies"
              component={Link}
              underline="none"
              className={classes.link}
            >
              Policies
            </MaterialLink>
          </Grid>
        </Grid>
        <Grid className={classes.bottomWrapper}>
          <Grid
            display="flex"
            alignItems="center"
            marginBottom={isMobile && "25px"}
          >
            <Typography color="#585858">Rixsus</Typography>
            <span
              className={classes.dot}
              style={{ margin: "0 10px", backgroundColor: "#585858" }}
            ></span>
            <Typography color="#585858">All rights reserved</Typography>
          </Grid>
          <Grid display={"flex"} position={"relative"}>
            <Email email="support@rixsus.com">
              <img alt="email" style={{ margin: "0 5px" }} src={email} />
            </Email>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
