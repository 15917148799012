import { Container, Grid2 as Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomCard from "../Card";
import Navbar from "../Navbar";
import mainImage from "../../img/ourCompany-bg.svg";
import Header from "../Header";
import { useEffect, useState } from "react";
import HomeFullItem from "../HomeFullItem";
import Footer from "../Footer";
import SemiCard from "../SemiCard";
import useMobileHook from "../Hooks";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundImage: `url(${mainImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    webkitBackgroundSize: "contain",
    oBackgroundSize: "contain",
    mozBackgroundSize: "contain",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "initial",
      webkitBackgroundSize: "initial",
      oBackgroundSize: "initial",
      mozBackgroundSize: "initial",
    },
  },
  cardWrapper: {
    position: "absolute",
    margin: "-50px -150px",
  },
  header: {
    backgroundColor: "transparent",
    webkitTransition: "background-color 700ms linear",
    msTransition: "background-color 700ms linear",
    transition: "background-color 700ms linear",
  },
  header2: {
    backgroundColor: "#FFF",
    zIndex: "9999",
    webkitTransition: "background-color 400ms linear",
    msTransition: "background-color 400ms linear",
    transition: "background-color 400ms linear",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
}));
const OurCompany = () => {
  const classes = useStyles();
  const [header, setHeader] = useState("header");
  const isMobile = useMobileHook();

  const listenScrollEvent = () => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div className={classes.main}>
      <div
        className={classes[header]}
        style={{
          position: "sticky",
          width: "100%",
          top: "0px",
        }}
      >
        <Navbar showHorizontalBar={header === "header"} />
      </div>
      <div>
        <Container
          container
          maxWidth="xl"
          style={{
            height: "100%",
            overflowX: "unset",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header from={"ourCompany"} />

          <Grid style={{ marginTop: "250px" }}>
            <Typography
              textAlign={"center"}
              variant="h3"
              fontSize={isMobile && "32px"}
              width={"100%"}
              paddingTop={"20px"}
            >
              What makes us different?
            </Typography>
            <Grid
              container
              justifyContent="space-between"
              margin="50px 0"
              alignItems={"center"}
              gap="20px"
            >
              <SemiCard
                title="The true first always."
                body="No matter how bad or good is the news we communicate always with transparency."
              />
              <SemiCard
                title="Process enthusiasts,"
                body="all companies small or big have their challenges and we love to build the best solution possible for each company."
              />
            </Grid>
            <Grid
              container
              margin="50px 0 100px 0"
              alignItems={"center"}
              gap="20px"
              justifyContent="space-between"
            >
              <SemiCard
                title="We always find the solution "
                body="in order to help our clients."
              />
              <SemiCard
                title="Industry changers we focus and"
                body="aim to make it different."
                bodyBold={true}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        className={classes.main}
        style={{ backgroundImage: "none", backgroundColor: "#F5F5F5" }}
      >
        <Container
          container
          maxWidth="xl"
          style={{
            height: "100%",
            overflowX: "unset",
            display: "flex",
            flexDirection: "column",
            paddingBottom: "70px",
          }}
        >
          <Typography
            textAlign={"center"}
            variant="h3"
            width={"100%"}
            padding={"100px 0 50px 0"}
          >
            Our values
          </Typography>
          <Grid
            margin={isMobile ? "20px auto 15px auto" : "20px auto 120px auto"}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={isMobile ? "15px" : "120px"}
            flexDirection={isMobile ? "column" : "row"}
          >
            <CustomCard
              withBtn={false}
              title={"Excellence"}
              body={
                "The most important value is our seeking for excellence and always build the best team with the same values and energy of Rixsus."
              }
            />
            <CustomCard
              withBtn={false}
              title={"Trust"}
              body={
                "We understand the most important part in all e-commerce is the payment so you must have a trusted partner."
              }
            />
            <CustomCard
              withBtn={false}
              title={"Simple"}
              body={
                "Dealing with financial problems is hard, our aim is to make the difficult simple in ways  the industry did not see it until now."
              }
            />
          </Grid>
          <Grid
            margin={"0 auto 20px auto"}
            display={"flex"}
            justifyContent={"flex-start"}
            flexDirection={isMobile ? "column" : "row"}
            alignItems={"center"}
            gap={isMobile ? "15px" : "120px"}
          >
            <CustomCard
              withBtn={false}
              title={"Energy"}
              body={
                "This is the key of our daily tasks and love to face new challenges."
              }
            />
            <CustomCard
              withBtn={false}
              title={"Teamwork"}
              body={
                "Collaborative listen, analize and create solutions side by side with our most important asset, our client."
              }
            />
            <CustomCard
              withBtn={false}
              title={"Attentive"}
              body={
                "Always ready to help. No matter the situation or the day or the week our true compromise in deliver a unique solutions to our users."
              }
            />
          </Grid>
        </Container>
      </div>
      <HomeFullItem />
      <Footer />
    </div>
  );
};

export default OurCompany;
