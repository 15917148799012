import {
  Toolbar,
  Typography,
  Button,
  Grid2 as Grid,
  Divider,
  Link as MaterialLink,
  Container,
  IconButton,
  Drawer,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../img/logo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useState } from "react";
import "./Navbar.css";
import useMobileHook from "../Hooks";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between !important",
    padding: "20px 0",
    boxShadow: "none",
    display: "flex",
  },
  loginBtn: {
    width: "100px",
    color: "#06AFD6 !important",
    backgroundColor: "#FFF !important",
    border: "1px solid #06AFD6 !important",
    marginRight: "10px !important",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#FFF !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      color: "#06AFD6 !important",
      backgroundColor: "#FFF !important",
      border: "1px solid #06AFD6 !important",
      marginRight: "10px !important",
      textAlign: "center",
    },
  },
  imgLogo: {
    [theme.breakpoints.down("sm")]: {
      width: "160px",
    },
  },
  tryBtn: {
    width: "120px",
    color: "#FFF !important",
    boxShadow: "none !important",

    "&:hover": {
      backgroundColor: "#06AFD6 !important",
    },
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #00B1D9 !important   ",
      borderRadius: "5px",
      backgroundColor: "#014E99 !important",
      padding: "10px 20px !important",
      color: "#fff !important",
      width: "100%",
      textAlign: "center",
      zIndex: "99999",
      "&:hover": {
        backgroundColor: "#014E99 !important",
      },
    },
  },

  navbarItem: {
    color: "#505050",
    marginBottom: "10px !important",
    "&:hover": {
      color: "#000",
      webkitTransition: "color 1000ms linear",
      msTransition: "color 1000ms linear",
      transition: "color 1000ms linear",
    },
  },
  navbarLink: {
    color: "#505050 !important",
    marginRight: "32px !important",
    marginBottom: "5px",
    "&:hover::after": {
      width: "100%",
      position: "relative",
      top: "1px",
    },
    "&::after": {
      content: '""',
      display: "block",
      width: "0",
      height: "1px",
      background: "#505050",
      transition: "width .3s",
      position: "relative",
      top: "1px",
    },
  },
}));

const Navbar = ({ showHorizontalBar = true }) => {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const isMobile = useMobileHook();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const drawerWidth = 372;

  return (
    <>
      <Container maxWidth="xl" overflow={open ? "hidden" : undefined}>
        <Toolbar className={classes.root} disableGutters={true}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Link to={"/"}>
              <img src={logo} alt="Main-logo" className={classes.imgLogo} />
            </Link>
            {isMobile ? (
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  sx={{ ...(open && { display: "none" }) }}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            ) : (
              <Grid container justifyContent="flex-end" spacing={6}>
                <Grid
                  className={classes.linkContainer}
                  alignItems="center"
                  justifyContent="space-between"
                  display="flex"
                >
                  <MaterialLink
                    className={classes.navbarLink}
                    component={Link}
                    underline="none"
                    to="/"
                    style={{
                      borderBottom:
                        location.pathname === "/"
                          ? "1px solid #505050"
                          : "none",
                    }}
                  >
                    <Typography variant="h6" className={classes.navbarItem}>
                      {" "}
                      Home{" "}
                    </Typography>
                  </MaterialLink>
                  <MaterialLink
                    className={classes.navbarLink}
                    component={Link}
                    underline="none"
                    to="/our-company"
                    style={{
                      borderBottom:
                        location.pathname === "/our-company"
                          ? "1px solid #505050"
                          : "none",
                    }}
                  >
                    <Typography
                      style={{ textTransform: "none " }}
                      variant="h6"
                      className={classes.navbarItem}
                    >
                      Our company
                    </Typography>
                  </MaterialLink>
                  <MaterialLink
                    className={classes.navbarLink}
                    component={Link}
                    underline="none"
                    to="/contact-us"
                    style={{
                      borderBottom:
                        location.pathname === "/contact-us"
                          ? "1px solid #505050"
                          : "none",
                    }}
                  >
                    <Typography
                      style={{ textTransform: "none " }}
                      variant="h6"
                      className={classes.navbarItem}
                    >
                      Contact us
                    </Typography>
                  </MaterialLink>
                </Grid>
              </Grid>
            )}
          </div>
        </Toolbar>
        {showHorizontalBar && (
          <Divider
            style={{
              border: "1px solid #C1CACF",
              width: "100vw",
              position: "relative",
              left: "50%",
              right: "50%",
              marginLeft: "-50vw",
              marginRight: "-50vw",
            }}
          />
        )}
      </Container>
      <Drawer
        sx={{
          width: drawerWidth,

          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "#06AFD6",
            zIndex: "99999999",
          },
          "& svg": {
            color: "#FFF",
            "&:hover": {
              backgroundColor: "transparent !important",
            },
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Grid display="flex" alignItems="flex-start" flexDirection={"column"}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          <Grid
            display="flex"
            alignItems="flex-start"
            flexDirection={"column"}
            width={"100%"}
          >
            <Typography
              fontSize={"24px"}
              color={"#FFF"}
              fontWeight={"28.44px"}
              padding={"20px 0 50px 30px"}
              textAlign={"center"}
            >
              Menu
            </Typography>
            <Divider color={"#3ED0F2"} width={"100%"} />
            <MaterialLink
              component={Link}
              underline="none"
              to="/"
              width={"100%"}
            >
              <Typography
                fontSize={"16px"}
                color={"#FFF"}
                fontWeight={"18.96px"}
                padding={"20px 0 20px 30px"}
                textAlign={"left"}
              >
                Home
              </Typography>
            </MaterialLink>
            <Divider color={"#3ED0F2"} width={"100%"} />
            <MaterialLink
              component={Link}
              underline="none"
              to="/our-company"
              width={"100%"}
            >
              <Typography
                fontSize={"16px"}
                color={"#FFF"}
                fontWeight={"18.96px"}
                padding={"20px 0 20px 30px"}
                textAlign={"left"}
              >
                Our company
              </Typography>
            </MaterialLink>
            <Divider color={"#3ED0F2"} width={"100%"} />
            <MaterialLink
              component={Link}
              underline="none"
              to="/contact-us"
              width={"100%"}
            >
              <Typography
                fontSize={"16px"}
                color={"#FFF"}
                fontWeight={"18.96px"}
                padding={"20px 0 20px 30px"}
                textAlign={"left"}
              >
                Contact us
              </Typography>
            </MaterialLink>
            <Divider color={"#3ED0F2"} width={"100%"} />
            <div style={{ padding: "50px 10px 10px 10px", width: "100%" }}>
              <MaterialLink
                component={Link}
                underline="none"
                to={{ pathname: "https://dashboard.rixsus.com/" }}
                target={"_blank"}
                width={"100%"}
              >
                <Button className={classes.loginBtn}> LOGIN </Button>
              </MaterialLink>
            </div>
            <div style={{ padding: "10px", width: "100%" }}>
              <MaterialLink
                component={Link}
                underline="none"
                to={{ pathname: "https://dashboard.rixsus.com/sign-up" }}
                target={"_blank"}
                width={"100%"}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.tryBtn}
                >
                  Try Rixsus
                </Button>
              </MaterialLink>
            </div>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default Navbar;
