import { Grid2 as Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMobileHook from "../Hooks";

const useStyles = makeStyles((theme) => ({
  img: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const HomeItem = ({
  image,
  title,
  bodyFirstPart,
  bodySecondPart,
  imagePosition,
  background = "#fff",
  padding = "0",
}) => {
  const classes = useStyles();
  const isMobile = useMobileHook();
  return (
    <Grid
      justifyContent={imagePosition === "left" ? "flex-start" : "flex-start"}
      display="flex"
      padding={padding}
      alignItems="center"
      gap={isMobile ? "0px" : "350px"}
      flexDirection={
        isMobile ? "column" : imagePosition === "left" ? "row" : "row-reverse"
      }
      style={{ backgroundColor: background }}
    >
      <Grid>
        <img
          alt="class"
          src={image}
          className={classes.img}
          style={
            imagePosition === "left"
              ? { borderBottomRightRadius: "20px", marginBottom: "40px" }
              : { borderBottomLeftRadius: "20px", marginBottom: "40px" }
          }
        />
      </Grid>
      <Grid padding={"0 25px"}>
        <Typography
          variant="h3"
          paddingBottom="20px"
          fontSize={isMobile && "32px"}
        >
          {title}
        </Typography>
        <Typography fontSize={isMobile && "16px"} variant="h4">
          {bodyFirstPart}
        </Typography>
        <Typography fontSize={isMobile && "16px"} variant="h4">
          {bodySecondPart}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HomeItem;
