import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomCard from "../Card";
import Navbar from "../Navbar";
import mainImage from "../../img/main-background.png";
import Header from "../Header";
import HomeItem from "../HomeItem";
import homeMapImg from "../../img/home-map.svg";
import homeOffice from "../../img/office-home.svg";
import { useEffect, useState } from "react";
import HomeFullItem from "../HomeFullItem";
import Footer from "../Footer";
import useMobileHook from "../Hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    margin: "20px 0px",
    boxShadow: "none",
  },
  main: {
    backgroundImage: `url(${mainImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    webkitBackgroundSize: "contain",
    oBackgroundSize: "contain",
    mozBackgroundSize: "contain",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url(${mainImage})`,
      backgroundColor: "transparent",
      backgroundSize: "initial",
      paddingBottom: "25px",
      webkitBackgroundSize: "initial",
      oBackgroundSize: "initial",
      mozBackgroundSize: "initial",
    },
  },
  cardWrapper: {
    position: "absolute",
    margin: "-50px -150px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      margin: "20px 0",
    },
  },
  header: {
    backgroundColor: "transparent",
    webkitTransition: "background-color 700ms linear",
    msTransition: "background-color 700ms linear",
    transition: "background-color 700ms linear",
  },
  header2: {
    backgroundColor: "#FFF",
    zIndex: "999999",
    webkitTransition: "background-color 400ms linear",
    msTransition: "background-color 400ms linear",
    transition: "background-color 400ms linear",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  middleContainer: {
    marginTop: "350px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "400px",
    },
  },
}));
const Home = () => {
  const isMobile = useMobileHook();

  const classes = useStyles();

  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 70) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <div className={classes.main}>
      <div
        className={classes[header]}
        style={{
          position: "sticky",
          width: "100%",
          top: "0px",
        }}
      >
        <Navbar showHorizontalBar={header === "header"} />
      </div>
      <div>
        <Container maxWidth="xl">
          <Header from={"home"} />
        </Container>

        <Container maxWidth="xl" className={classes.middleContainer}>
          <Box sx={{ textAlign: "center", marginBottom: 4 }}>
            <Typography variant="h3">Growth your business.</Typography>
            <Typography variant="h4">
              We provide full cross-border payment solutions for LATAM.
            </Typography>
          </Box>

          <Grid
            container
            justifyContent="center"
            spacing={4}
            sx={{ padding: "20px 0 60px 0" }}
          >
            <Grid>
              <CustomCard
                title="Alternative payment methods (APM)"
                body="An APM is a payment approach that gives added incentive payments to provide high-quality and cost-efficient care based in  location and users preferences."
              />
            </Grid>
            <Grid>
              <CustomCard
                title="Bank transfers"
                body="One of the most popular payments methods in Latam is the local bank transfers and we offer instant"
              />
            </Grid>
            <Grid>
              <CustomCard
                title="Payouts"
                body="Local solutions and payouts automatization to develop and build local relationship with your customers."
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        className={classes.main}
        style={{ backgroundImage: "none", backgroundColor: "#F5F5F5" }}
      >
        <HomeItem
          padding="40px 0 "
          background="#F5F5F5"
          imagePosition="left"
          image={homeMapImg}
          title="Access to new markets"
          bodyFirstPart="Launch a new market is a Challenge."
          bodySecondPart="Our main objective is build the best solutions for you."
        />
        <div
          className={classes.main}
          style={{
            backgroundImage: "none",
            backgroundColor: "#FFF",
          }}
        >
          <HomeItem
            image={homeOffice}
            title="Transparency on each step."
            bodyFirstPart="Understanding the rules, regulations, laws and all the points"
            bodySecondPart=" that involve your business in a new region is the key of success."
          />
        </div>
      </div>
      <HomeFullItem />
      <Footer />
    </div>
  );
};

export default Home;
